<template>
    <section>
        <PageHeader>
            <template v-slot:header>
                <span @click="counterShowCBHPMImport++">Tabelas</span>
            </template>
            <template v-slot:actions>
                <Loading
                    :class="{ icon: true, reload: true, loading }" 
                    @click="() => !loading && getTables()"
                    v-b-tooltip.hover title="Recarregar tabelas"                    
                />
                <b-button v-if="counterShowCBHPMImport >= 10" variant="primary" @click="showTableCBHPMModal">Nova tabela CBHPM</b-button>
                <b-button variant="primary" @click="showTableModal">Nova tabela</b-button>
            </template>
        </PageHeader>
        <br/>
        <b-row>
            <b-col cols="4">
                <b-form-group>
                    <label>Tabela</label>
                    <b-form-input 
                        v-model="tableQuery"
                        placeholder="Buscar tabela"
                        @input="debounceQuery"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group>
                    <label>Convênio</label>
                    <b-form-input 
                        v-model="healthPlanQuery"
                        placeholder="Buscar por convênio"
                        @input="debounceQuery"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <TypeSelector grouped v-model="type" />
            </b-col>
        </b-row>
        <TableTables 
            :tables="tables" 
            @edit="editTable"
            @delete="deleteTable"
            @saved="() => getTables()"
        />
        <div class="pagination-position">
            <b-pagination
                v-model="page"
                :total-rows="count"
                :per-page="limit"
                first-number
                last-number
                size="sm"
            />
        </div>

        <TableModal :table="table" @saved="onSaved"/>
        <TableCbhmpModal />
    </section>
</template>
<script>
import api from '@tables/api'
import { debounce } from 'lodash';
import { getCurrentClinic } from '@/utils/localStorageManager'
import { saveAs } from 'file-saver'

export default {
    components: {
        Loading: () => import('@/assets/icons/loading.svg'),
        PageHeader: () => import('@/components/General/PageHeader'),        
        TypeSelector: () => import('@items/components/TypeSelector'),
        TableTables: () => import('@tables/layouts/TableTables'),
        TableModal: () => import('@tables/modals/TableModal'),
        TableCbhmpModal: () => import('@tables/modals/TableCbhmpModal')
    },
    data() {
        return {
            counterShowCBHPMImport: 0,
            clinic: getCurrentClinic(),
            loading: false,
            tableQuery: null,
            healthPlanQuery: null,
            query: null,
            type: null,
            table: null,
            debounceQuery: null,
            tables: [],
            page: 1,
            count: 0,
            limit: 0
        }
    },
    created() {
        this.debounceQuery = debounce(this.getTables, 500)
        this.getTables()
    },
    methods: {
        getTables() {
            this.loading = true
            api.getTables(this.page, this.clinic.id, this.tableQuery, this.healthPlanQuery, this.type ? [this.type] : [])
                .then(({ data }) => {
                    this.count = data.total
                    this.limit = data.per_page
                    this.tables = data.data
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onSaved(item, openImportItemsModal) {
            const index = this.tables.findIndex(({ id }) => id === item.id)
            index >= 0 ?
                this.tables.splice(index, 1, item) :
                this.tables.push(item)
        },
        showTableCBHPMModal() {
            this.$bvModal.show('table-cbhpm-modal')
        },
        showTableModal() {
            this.table = null
            this.$bvModal.show('table-modal')
        },
        editTable(table) {
            this.table = table
            this.$bvModal.show('table-modal')
        },
        async deleteTable(table) {
            try {
                await api.deleteTable(table.id)
                this.$toast.success('Tabela excluída com sucesso')
                const index = this.tables.findIndex(({ id }) => id === table.id)
                this.tables.splice(index, 1)
            } catch (error) {
                this.$toast.error(error.message)
            } finally {
                
            }
        },
        selectTemplateToDownload(ev) {
            ev.stopPropagation()
            this.$swal({
                id: 'select-template',
                title: 'Download template',
                input: 'select',
                inputLabel: 'Tabela',
                inputOptions: {
                tiss_table_procedures: 'Procedimentos',
                tiss_table_other_expenses: 'Outras despesas'
                },
                inputPlaceholder: 'Selecionar',
                showCancelButton: true,
                confirmButtonColor: '#305BF2',
                confirmButtonText: 'Download',
                cancelButtonText: 'Cancelar',
                reverseButtons: true,
                customClass: {
                cancelButton: 'btn-outline-danger',
                confirmButton: 'btn-primary'
                },
                inputValidator: value => {
                    return new Promise(resolve => {
                        if (value) resolve()
                        resolve('Você precisa selecionar uma tabela')
                    })
                }
            }).then(async res => {
                const { value, isConfirmed } = res
                if (isConfirmed) {
                    this.downloadCSVTemplate(value)
                }
            })
        },

        getCsv(template) {
            return {
                tiss_table_procedures:
                'Tabela;Código do procedimento;Procedimento;HM;C.OP;M2 de filme;Porte anestésico',
                tiss_table_other_expenses: `${process.env.VUE_APP_EYECAREBI_AWS_FILES_PUBLIC}tiss/Template_de_outras_despesas.xlsx`
            }[template]
        },
        isUrl(text) {
            return text.includes('http')
        },
        downloadCSVTemplate(template) {
            const isLoading = this.$loading.show()
            try {
                const templateToDownload = this.getCsv(template)
                if (this.isUrl(templateToDownload)) {
                window.open(templateToDownload, '_blank')
                return
                }
                const blob = new Blob([`\uFEFF${templateToDownload}`], {
                type: 'text/csv; charset=utf-8'
                })
                saveAs(blob, 'Template_de_procedimentos.xlsx')
            } catch (error) {
                console.log(error)
                this.$toast.error(error.message)
            } finally {
                isLoading.hide()
            }
        }
    },
    watch: {
        page() {
            this.getTables()
        },
        type() {
            this.getTables()
        }
    }
}
</script>
<style lang="scss" scoped>
.download-icon {
    fill: var(--blue-500);
    stroke: var(--blue-500);
}
</style>